AFRAME.registerComponent("group-opacity", {
    schema: {type: "number", default: 1},

    init: function () {
        this.bindMethods();

        this.el.addEventListener("object3dset", (evt) => {
            evt.detail.object.traverse(this.applyOpacity);
        });
    },

    update: function (oldData) {
        this.el.object3D.traverse(this.applyOpacity);
    },

    applyOpacity: function (node) {
        if (node.isMesh) {
            node.material.opacity = this.data;
            node.material.transparent = this.data < 1;
        }
    },

    /**
     * bind methods used in callbacks
     */
    bindMethods: function () {
        this.applyOpacity = this.applyOpacity.bind(this);
    },
});
